export const SK = {
    language: 'SK',
    navbarItem1: 'Domov',
    navbarItem2: 'Služby',
    navbarItem3: 'Galéria',
    navbarItem4: 'Kontakt',
    navbarItem5: 'Hodnotenie',
    slideshowText: 'Jazda po Havane',
    section: 'Galéria',
    contactUs: 'Kontaktujte nás',
    findUs: 'Nájdete nás',
    /////////////////RATING
    userOneName: 'Luis C.',
    userOneText: 'Skvelý zážitok!! Profesionálny prístup a bezpečná jazda. Môžem iba odporúčiť.',
    userTwoName: 'Charles F.',
    userTwoText: 'Dlho budem spomínať na tento zážitok! Ernesto a Osdel boli milí a dali nám skvelé tipy na ďalšie miesta, ktoré sú hodné návštevy. Ďakujeme, určite si vyberieme Havana Tour Cars aj nabudúce.',
    userThreeName: 'Michelle O.',
    userThreeText: 'Rada by som sa poďakovala za Vaše služby a bezpečnú jazdu Havanou, určite môžem iba odporúčiť nielen svojej rodine, ale aj kamarátom, ktorí pôjdu na Kubu. Ďakujem!',
    userFourName: 'Camila R.',
    userFourText: 'Ďakujem, všetko veľmi dobré. Pre HavanaTourCars by som mal len jedno odporúčanie: aby existovala väčšia flexibilita pri predĺžení trvania zájazdu, ak o to klient požiada. Stalo sa, že na konci požadovaného zájazdu, ktorý bol výborný, sme s manželom požiadali šoféra o predĺženie cesty o ďalšiu polhodinu, samozrejme nami zaplatenú, ale šofér nám veľmi milo povedal, že nemôže vyhovieť našu žiadosť, pretože mal naplánovanú ďalšiu prehliadku, ktorá by mala začať o 20 minút neskôr. V každom prípade sme boli s prehliadkou, ktorú sme absolvovali, veľmi spokojní.',
    /////////////////SERVICES
    service1Heading: 'Prechádzka po turistických atrakciách Havany',
    service1Text: 'Objavte to najlepšie z Havany v pohodlných klasických kabrioletoch. Poskytujeme bezpečný výlet pre každého, prevedieme vás starým mestom a obľúbenými miestami, kde si užijete tie najkrajšie výhľady. Máme dlhoročné skúsenosti v cestovnom ruchu a poznáme históriu Havany a celej Kuby.',
    service2Heading: 'Občasné prechádzky',
    service2Text: 'Oslávte svoje narodeniny, sviatok, svadobnú hostinu alebo inú významnú príležitosť na elegantnej prechádzke Havanou. Ford z roku 1952 a Chevrolet z roku 1954 vás môžu vziať na prehliadku Havany a zaručiť vám nezabudnuteľné zážitky na Kube. V závislosti od vašej požiadavky môžu prehliadky trvať 1 hodinu alebo viac.',
    service3Heading: 'Našou prioritou je spokojnosť zákazníka',
    service3Text: 'Sprievodcami budeme Ernesto a Osdel, ktorí vám radi poradíme a splníme vaše želania s kompletnou zárukou. Našou prioritou je poskytovať kvalitné služby našim klientom a tým zabezpečiť ich spokojnosť.',
    service4Heading: 'Transfer',
    service4Text1: 'Transfer - letisko José Martí - Hotel',
    service4Text2: 'Transfer - Hotel - letisko José Martí',
    ///////////////////DESTINATIONS
    destinationHeading1: 'Soroa',
    destinationDistance1: 'vzdialenosť 80 km, 1 h 20 min.',
    destination1LiOne: 'Vodopád, motel, bazén',
    destination1LiTwo: 'Soroa Orchid Garden, s najväčšou zbierkou orchideí na Kube',
    destinationHeading2: 'Varadero',
    destinationDistance2: 'vzdialenosť 145 km, 2 h 17 min.',
    destination2LiOne: 'Najznámejšia pláž na Kube, turistické centrá, hotely',
    destinationHeading3: 'Východné pláže',
    destinationDistance3: 'vzdialenosť 24 km, 35 min.',
    destination3LiOne: 'Pláže s bielym pieskom východne od Havany, hotely',
    destinationHeading4: 'Finca Vigía',
    destinationDistance4: 'vzdialenosť 10 km, 20 min.',
    destination4LiOne: 'Múzeum Ernesta Hemingwaya, kde E. Hemingway žil viac ako 20 rokov',
    internationalAirport: 'José Martí medzinárodné letisko',
    /////////////////Map
    cuba: 'Kuba',
    //////////////////Havana destinations
    piazzaDeLaRevolucion: 'Plaza de la Revolución - Pamätník Josého Martího',
    capitol: 'Capitolio',
    malecon: 'Malecón',
    centralPark: 'Centrálny Park',
    laRampa: 'Ulica La Rampa',
    havanaHotels: 'Hotely v Havane',
    havanaForest: 'Havanský les',
    havanaCementery: 'Havanský cintorín',
    centrum: 'Historické centrum Havany',
    castle: 'Hrad Morro',
    cabana: 'Pevnosť Cabaňa - výstrel z dela o 21:00 hod.',
    //
    inHavana: 'v Havane',
    havana: 'Havana',
}

export const EN = {
    language: 'EN',
    navbarItem1: 'Home',
    navbarItem2: 'Services',
    navbarItem3: 'Gallery',
    navbarItem4: 'Contact',
    navbarItem5: 'Rating',
    slideshowText: 'Havana Tour',
    section: 'Gallery',
    contactUs: 'Contact us',
    findUs: 'Find us',
    /////////////////RATING
    userOneName: 'Luis C.',
    userOneText: 'Great experience!! Professional approach and safe driving. I can only recommend.',
    userTwoName: 'Charles F.',
    userTwoText: 'I will remember this experience for a long time! Ernesto and Osdel were nice and gave us great tips on other places worth visiting. Thank you, we will definitely choose Havana Tour Cars again next time.',
    userThreeName: 'Michelle O.',
    userThreeText: 'I would like to thank you for your services and a safe ride through Havana, I can definitely recommend you not only to my family, but also to my friends who are going to Cuba. Well thank you!',
    userFourName: 'Camila R.',
    userFourText: 'Thank you, everything is very good. I would only have one recommendation for HavanaTourCars: that there be more flexibility in extending the duration of the tour if the client requests it. It so happened that at the end of the requested tour, which was excellent, my husband and I asked the driver to extend the trip by another half hour, paid for by us of course, but the driver very kindly told us that he could not accommodate our request because he had another tour scheduled that would it was supposed to start 20 minutes later. In any case, we were very satisfied with the tour we took.',
    //////////////////SERVICES
    service1Heading: 'A walk through the tourist attractions of Havana',
    service1Text: 'Discover the best of Havana in comfortable convertible classic cars. We provide a safe trip for everyone; we will guide you through the old town and through favorite places where you can enjoy the most beautiful views. We have many years of experience in tourism and know the history of Havana and all of Cuba.',
    service2Heading: 'Occasional walks',
    service2Text: 'Celebrate your birthday, holiday, wedding reception or other important occasion on an elegant stroll through Havana. A 1952 Ford and a 1954 Chevrolet can take you on a tour of Havana and guarantee you unforgettable experiences in Cuba. Depending on your request, the tours may last 1 hour or more.',
    service3Heading: 'Our priority is customer satisfaction',
    service3Text: 'The guides will be Ernesto and Osdel, who will be happy to advise you and fulfill your wishes with full guarantee. Our priority is to provide quality services to our clients and thus ensure their satisfaction.',
    service4Heading: 'Transfer',
    service4Text1: 'Transfer - José Martí Airport - Hotel',
    service4Text2: 'Transfer - Hotel - José Martí Airport',
    ///////////////////DESTINATIONS
    destinationHeading1: 'Soroa',
    destinationDistance1: 'distance 80 km, 1 h 20 min.',
    destination1LiOne: 'Waterfall, motel, pool',
    destination1LiTwo: 'Soroa Orchid Garden, with the largest collection of orchids in Cuba',
    destinationHeading2: 'Varadero',
    destinationDistance2: 'distance 145 km, 2 h 17 min.',
    destination2LiOne: 'The most famous beach in Cuba, tourist centers, hotels',
    destinationHeading3: 'Eastern Beaches',
    destinationDistance3: 'distance 24 km, 35 min.',
    destination3LiOne: 'White sand beaches east of Havana, hotels',
    destinationHeading4: 'Finca Vigía',
    destinationDistance4: 'distance 10 km, 20 min.',
    destination4LiOne: 'Ernest Hemingway Museum, where E. Hemingway lived for more than 20 years',
    internationalAirport: 'José Martí international Airport',
    //////////////////Map
    cuba: 'Cuba',
    //////////////////Havana destinations
    piazzaDeLaRevolucion: 'Plaza de la Revolución - José Martí monument',
    capitol: 'Capitol',
    malecon: 'Malecón',
    centralPark: 'Central Park',
    laRampa: 'La Rampa street',
    havanaHotels: 'Hotels in Havana',
    havanaForest: 'Havana forest',
    havanaCementery: 'Havana cementery',
    centrum: 'The historic center of Havana',
    castle: 'Morro castle',
    cabana: 'Cabaňa Fortress - cannon shot at 9:00 p.m.',
    //
    inHavana: 'in Havana',
    havana: 'Havana',
}

export const RU = {
    language: 'RU',
    navbarItem1: 'Домой',
    navbarItem2: 'Услуги',
    navbarItem3: 'Галерея',
    navbarItem4: 'Контакт',
    navbarItem5: 'Рейтинг',
    slideshowText: 'Тур по Гаване',
    section: 'Галерея',
    contactUs: 'Свяжитесь с нами',
    findUs: 'Найдите нас',
    /////////////////RATING
    userOneName: 'Луис К.',
    userOneText: 'Отличный опыт!! Профессиональный подход и безопасное вождение. Я могу только рекомендовать.',
    userTwoName: 'Чарльз Ф.',
    userTwoText: 'Я запомню этот опыт надолго!» Эрнесто и Осдел были милы и дали нам отличные советы о других местах, которые стоит посетить. Спасибо, в следующий раз обязательно выберем Havana Tour Cars.',
    userThreeName: 'Мишель О.',
    userThreeText: 'Я хотел бы поблагодарить вас за ваши услуги и безопасную поездку по Гаване, я определенно могу рекомендовать вас не только своей семье, но и своим друзьям, которые собираются на Кубу. Что ж, спасибо тебе!',
    userFourName: 'Камила Р.',
    userFourText: 'Спасибо, все очень хорошо. У меня есть только одна рекомендация для HavanaTourCars: обеспечить большую гибкость в продлении продолжительности тура, если клиент этого попросит. Так получилось, что в конце запрошенной экскурсии, которая прошла отлично, мы с мужем попросили водителя продлить поездку еще на полчаса, конечно, оплаченную нами, но водитель очень любезно сказал нам, что не может удовлетворить нашу просьбу, потому что у него был запланирован еще один тур, который должен был начаться через 20 минут. В любом случае, мы остались очень довольны поездкой.',
    //////////////////SERVICIOS
    service1Heading: 'Прогулка по туристическим достопримечательностям Гаваны',
    service1Text: 'Откройте для себя лучшее из Гаваны в комфортабельных классических автомобилях с откидным верхом. Мы обеспечиваем безопасную поездку для всех; мы проведем вас по старому городу и любимым местам, где вы сможете насладиться самыми красивыми видами. Мы имеем многолетний опыт в туризме и знаем историю Гаваны и всей Кубы.',
    service2Heading: 'Редактируемые прогулки',
    service2Text: 'Отпразднуйте свой день рождения, праздник, свадьбу или другое важное событие во время элегантной прогулки по Гаване. Ford 1952 года и Chevrolet 1954 года могут взять вас на экскурсию по Гаване и гарантировать незабываемые впечатления на Кубе. В зависимости от вашего запроса экскурсии могут длиться 1 час и более.',
    service3Heading: 'Наш приоритет — удовлетворенность клиентов',
    service3Text: 'Гидами будут Эрнесто и Осдел, которые будут рады проконсультировать вас и с полной гарантией выполнить ваши пожелания. Нашим приоритетом является предоставление качественных услуг нашим клиентам и, таким образом, обеспечение их удовлетворенности.',
    service4Heading:'Передача',
    service4Text1: 'Трансфер - аэропорт Хосе Марти - отель',
    service4Text2: 'Трансфер - Отель - Аэропорт Хосе Марти',
    ////////////////////DESTINATIONS
    destinationHeading1: 'Сороа',
    destinationDistance1: 'расстояние 80 км, 1 час 20 мин.',
    destination1LiOne: 'Водопад, мотель, бассейн',
    destination1LiTwo: 'Сад орхидей Сороа с самой большой коллекцией орхидей на Кубе',
    destinationHeading2: 'Варадеро',
    destinationDistance2: 'расстояние 145 км, 2 часа 17 минут',
    destination2LiOne: 'Самый известный пляж Кубы, туристические центры, отели',
    destinationHeading3: 'Восточные пляжи',
    destinationDistance3: 'расстояние 24 км, 35 ​​мин.',
    destination3LiOne: 'Пляжи с белым песком к востоку от Гаваны, отели',
    destinationHeading4: 'Финка Вигия',
    destinationDistance4: 'расстояние 10 км, 20 мин.',
    destination4LiOne: 'Музей Эрнеста Хемингуэя, где Э. Хемингуэй прожил более 20 лет',
    internationalAirport: 'Международный аэропорт Хосе Марти',
    //////////////////MAP
    cuba: 'Куба',
    //////////////////Havana destinations
    piazzaDeLaRevolucion: 'Площадь Революции - памятник Хосе Марти',
    capitol: 'Капитолий',
    malecon: 'Малекон',
    centralPark: 'Центральный парк',
    laRampa: 'Улица Ла Рампа',
    havanaHotels: 'Отели Гаваны',
    havanaForest: 'Гаванский лес',
    havanaCementery: 'Гаванский цементный завод',
    centrum: 'Исторический центр Гаваны',
    castle: 'Замок Морро',
    cabana: 'Крепость Кабаня – пушечный выстрел в 21:00',
    //
    inHavana: 'в Гаване',
    havana: 'Гавана',
}

export const ES = {
    language: 'ES',
    navbarItem1: 'Inicio',
    navbarItem2: 'Servicios',
    navbarItem3: 'Galería',
    navbarItem4: 'Contacto',
    navbarItem5: 'Opiniones',
    slideshowText: 'Tour por La Habana',
    section: 'Galería',
    contactUs: 'Contáctenos',
    findUs: 'Encuéntranos',
    /////////////////RATING
    userOneName: 'Luis C.',
    userOneText: ' ¡¡Gran experiencia!! Enfoque profesional y conducción segura. Sólo puedo recomendar.',
    userTwoName: 'Charles F.',
    userTwoText: '¡Recordaré esta experiencia durante mucho tiempo! Ernesto y Osdel fueron amables y nos dieron excelentes consejos sobre otros lugares que vale la pena visitar. Gracias, definitivamente elegiremos Habana Tour Cars de nuevo la próxima vez.',
    userThreeName: 'Michelle O.',
    userThreeText: 'Me gustaría agradecerle por sus servicios y un recorrido seguro por La Habana, ciertamente puedo recomendarlo no solo a mi familia, sino también a mis amigos que van a Cuba. ¡Bueno, gracias!',
    userFourName: 'Camila R.',
    userFourText: 'Gracias, todo muy bueno. Solo tendría una recomendación para HavanaTourCars: Que haya mayor flexibilidad para extender la duración del recorrido si el cliente lo solicita. Ocurrió, que Al finalizar el recorrido solicitado, que fue excelente, mi esposo y yo solicitamos al chofer extender el viaje media hora más, por supuesto pagada por nosotros, pero el chofer muy amablemente nos dijo que no podía satisfacer nuestro pedido porque tenía programado otro recorrido que debería empezar 20 minutos después. De cualquier manera quedamos muy complacidos por el recorrido que tuvimos.',
    //////////////////SERVICIOS
    service1Heading: 'Un paseo por las atracciones turísticas de La Habana',
    service1Text: 'Descubra lo mejor de La Habana en cómodos autos clásicos descapotables. Brindamos un viaje seguro para todos, lo guiaremos por el casco antiguo y a través de lugares favoritos donde podrá disfrutar de las vistas más hermosas. Tenemos muchos años de experiencia en el turismo y conocemos la historia de La Habana y de toda Cuba.',
    service2Heading: 'Paseos ocasionales',
    service2Text: 'Celebre su cumpleaños, día festivo, recepción de boda u otra ocasión importante en un elegante paseo por La Habana. Un Ford de 1952 y un Chevrolet de 1954 pueden llevarle a un recorrido por La Habana y garantizarle experiencias inolvidables en Cuba. Atendiendo a su solicitud los recorridos pueden tener una duración de 1 hora o más.',
    service3Heading: 'Nuestra prioridad es la satisfacción del cliente',
    service3Text: 'Los guías seremos Ernesto y Osdel, que encantados lo asesoraremos y cumpliremos sus deseos con total garantía. Nuestra prioridad es brindar servicios de calidad a nuestros clientes y así asegurar su satisfacción.',
    service4Heading: 'Traslado',
    service4Text1: 'Traslado - Aeropuerto José Martí - Hotel',
    service4Text2: 'Traslado - Hotel - Aeropuerto José Martí',
    ///////////////////DESTINATIONS
    destinationHeading1: 'Soroa',
    destinationDistance1: 'distancia 83 km, 1 h.',
    destination1LiOne: 'Cascada, motel, piscina',
    destination1LiTwo: 'Orquideario de Soroa, con la mayor colección de orquídeas de Cuba',
    destinationHeading2: 'Varadero',
    destinationDistance2: 'distancia 145 km, 2 h  17 min.',
    destination2LiOne: ' La playa más famosa de Cuba, centros turísticos, hoteles',
    destinationHeading3: 'Playas del Este',
    destinationDistance3: 'distancia 24 km, 35 min.',
    destination3LiOne: 'Playas de arenas blancas al este de La Habana, hoteles',
    destinationHeading4: 'Finca Vigía',
    destinationDistance4: 'distancia 10 km, 20 min.',
    destination4LiOne: 'Casa Museo de Ernest Hemingway, lugar donde E.  Hemingway vivió  durante más de 20 años',
    internationalAirport: 'Aeropuerto Internacional José Martí',
    //////////////////Map
    cuba: 'Cuba',
    //////////////////Havana destinations
    piazzaDeLaRevolucion: 'Plaza de la Revolución - Monumento a José Martí',
    capitol: 'Capitol',
    malecon: 'Malecón',
    centralPark: 'Parque Central',
    laRampa: 'Calle La Rampa',
    havanaHotels: 'Hoteles de La Habana',
    havanaForest: 'Bosque de La Habana',
    havanaCementery: 'Cementerio de La Habana',
    centrum: 'Casco histórico ',
    castle: 'Castillo del Morro',
    cabana: 'Fortaleza de la Cabaña - cañonazo a las 21:00',
    //
    inHavana: 'en La Habana',
    havana: 'La Habana',
}

export const FR = {
    language : 'FR',
    navbarItem1: 'Accueil',
    navbarItem2: 'Services',
    navbarItem3: 'Galerie',
    navbarItem4: 'Contact',
    navbarItem5: 'Note',
    slideshowText: 'Havana Tour',
    section: 'Galerie',
    contactUs: 'Contactez-nous',
    findUs: 'Trouvez-nous',
    /////////////////NOTATION
    userOneName: 'Luis C.',
    userOneText: 'Super expérience !! Approche professionnelle et conduite sécuritaire. Je ne peux que recommander.',
    userTwoName: 'Charles F.',
    userTwoText: "Je me souviendrai longtemps de cette expérience ! Ernesto et Osdel étaient gentils et nous ont donné d'excellents conseils sur d'autres endroits à visiter. Merci, nous choisirons certainement à nouveau Havana Tour Cars la prochaine fois.",
    userThreeName:'Michelle O.',
    userThreeText:'Je voudrais vous remercier pour vos services et pour votre voyage en toute sécurité à La Havane, je peux certainement vous recommander non seulement à ma famille, mais aussi à mes amis qui partent à Cuba. Bien merci!',
    userFourName:'Camila R.',
    userFourText:"Merci, tout va très bien. Je n'aurais qu'une seule recommandation pour HavanaTourCars : qu'il y ait plus de flexibilité dans l'extension de la durée du circuit si le client le demande. Il se trouve qu'à la fin de la visite demandée, qui était excellente, mon mari et moi avons demandé au chauffeur de prolonger le trajet d'une demi-heure supplémentaire, à notre charge bien sûr, mais le chauffeur nous a gentiment répondu qu'il ne pouvait pas répondre à notre demande car il avait prévu une autre tournée qui devait commencer 20 minutes plus tard. En tout cas, nous avons été très satisfaits de la tournée que nous avons effectuée.",
    //////////////////PRESTATIONS DE SERVICE
    service1Heading: 'Une promenade à travers les attractions touristiques de La Havane',
    service1Text : "Découvrez le meilleur de La Havane dans de confortables voitures classiques décapotables. Nous offrons un voyage en toute sécurité à tout le monde; nous vous guiderons à travers la vieille ville et à travers les lieux préférés où vous pourrez profiter des plus belles vues. Nous avons de nombreuses années d'expérience dans le tourisme et connaissons l'histoire de La Havane et de tout Cuba.",
    service2Heading: 'Balades occasionnelles',
    service2Text: "Célébrez votre anniversaire, vos vacances, votre réception de mariage ou toute autre occasion importante lors d'une promenade élégante à La Havane. Une Ford de 1952 et une Chevrolet de 1954 peuvent vous faire visiter La Havane et vous garantir des expériences inoubliables à Cuba. Selon votre demande, les visites peuvent durer 1 heure ou plus.",
    service3Heading: 'Notre priorité est la satisfaction du client',
    service3Text : 'Les guides seront Ernesto et Osdel, qui se feront un plaisir de vous conseiller et de réaliser vos souhaits en toute garantie. Notre priorité est de fournir des services de qualité à nos clients et ainsi assurer leur satisfaction.',
    service4Heading: 'Transfert',
    service4Text1: 'Transfert - Aéroport José Martí - Hôtel',
    service4Text2: 'Transfert - Hôtel - Aéroport José Martí',
    ///////////////////DESTINATIONS
    destinationHeading1:'Soroa',
    destinationDistance1: 'distance 80 km, 1 h 20 min.',
    destination1LiOne: 'Cascade, motel, piscine',
    destination1LiTwo: "Jardin d'orchidées Soroa, avec la plus grande collection d'orchidées de Cuba",
    destinationTitre2: 'Varadero',
    destinationDistance2: 'distance 145 km, 2 h 17 min.',
    destination2LiOne: 'La plage la plus célèbre de Cuba, les centres touristiques, les hôtels',
    destinationHeading3:"Plages de l'Est",
    destinationDistance3: 'distance 24 km, 35 min.',
    destination3LiOne: "Plages de sable blanc à l'est de La Havane, hôtels",
    destinationHeading4:'Finca Vigía',
    destinationDistance4: 'distance 10 km, 20 min.',
    destination4LiOne: "Musée Ernest Hemingway, où E. Hemingway a vécu pendant plus de 20 ans",
    internationalAirport: "Aéroport international José Martí",
    //////////////////MAP
    Cuba: 'Cuba',
    //////////////////Destinations La Havane
    piazzaDeLaRevolucion: 'Plaza de la Revolución - Monument José Martí',
    capitol: 'Capitol',
    malecon: 'Malecón',
    centralPark: 'Central Park',
    laRampa: "rue La Rampa",
    havanaHotels: 'Hôtels à La Havane',
    havanaForest: 'Forêt de La Havane',
    havanaCementery: 'cimenterie de La Havane',
    centrum: "Le centre historique de La Havane",
    castle: 'Château de Morro',
    cabana: 'Forteresse de Cabaňa - coup de canon à 21h00',
    //
    inHavana: 'à La Havane',
    havana: 'La Havane',
}