import React from 'react'
import './services.css'
import photo from '../images/servicesPhoto.JPG'


export default function Services(props) {
  return (
    <div id='services'>
        <h1 id='servicesHeading'>{props.language.navbarItem2}</h1>
        <div className='serviceGridContainer'>
          <div className='servicePhotoContainer'>
            <img className='servicePhoto' src={photo} alt="" loading='lazy'/>
          </div>
          <div className='service'>
            <h2 className='serviceH2'>{props.language.service1Heading}</h2>
            <p>{props.language.service1Text}</p>
          </div>
          <div className='service'>
            <h2 className='serviceH2'>{props.language.service4Heading}</h2>
            <p>{props.language.service4Text1}</p>
            <p>{props.language.service4Text2}</p>
          </div>
          <div className='service'>
            <h2 className='serviceH2'>{props.language.service2Heading}</h2>
            <p>{props.language.service2Text}</p>
          </div>
          <div className='service'>
            <h2 className='serviceH2'>{props.language.service3Heading}</h2>
            <p>{props.language.service3Text}</p>
          </div>
        </div>
    </div>
  )
}