import React from 'react'
import './slideshow.css'
import titlePhoto from '../images/titlePhoto-min.jpg'

export default function Slideshow(props) {
  return (
    <div id='slideshowContainer'>
        <h1 className='slideshowText'>{props.language.slideshowText}</h1>
          <img src={titlePhoto} className='backgroundPhoto' alt="" loading='lazy'/>
    </div>
  )
}
