import './rating.css'
import { FcBusinesswoman, FcBusinessman } from "react-icons/fc";
import { IoStar } from "react-icons/io5";


export default function Rating(props) {

  return (
    <div id='rating'>
        <h1 id='ratingHeading'>{props.language.navbarItem5}</h1>
        <div id='cardContainer'>
          <Card name={props.language.userOneName} text={props.language.userOneText} gender={'man'} stars={5} date={'14-2-2023'}/>
          <Card name={props.language.userTwoName} text={props.language.userTwoText} gender={'man'} stars={5} date={'25-5-2023'}/>
          <Card name={props.language.userThreeName} text={props.language.userThreeText} gender={'woman'} stars={5} date={'7-9-2023'}/>
          <Card name={props.language.userFourName} text={props.language.userFourText} gender={'woman'} stars={4} date={'19-11-2023'}/>
        </div>
    </div>
  )
}

function Card (props) {
  const fiveStars = [<IoStar className='ratingStar'/>, <IoStar className='ratingStar'/>,<IoStar className='ratingStar'/>,<IoStar className='ratingStar'/>,<IoStar className='ratingStar'/>]
  const fourStars = [<IoStar className='ratingStar'/>, <IoStar className='ratingStar'/>,<IoStar className='ratingStar'/>,<IoStar className='ratingStar'/>]

  return (
  <div id='card'>
    <div className='cardContent'>
      <div className="cardHeadingContainer">
        {props.gender === 'man' && <FcBusinessman className='manIcon'/>}
        {props.gender === 'woman' && <FcBusinesswoman className='womanIcon'/>}
        <h2 className='userName'> {props.name} </h2>
        <span className='ratingDate'>{props.date}</span>
      </div>
      <p className='userText'>
        {props.text}
      </p>
      <div className='starsContainer'>
        {props.stars  === 5 && fiveStars}
        {props.stars === 4 && fourStars}
        <span className='starsCountText'>{props.stars} / 5</span>
      </div>
    </div>
  </div>
  )
}