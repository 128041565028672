import React from 'react'
import './destinations.css'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pin from '../images/pin.png'

export default function Destinations(props) {

    const cubaMarkers = [
        { lat: 22.77048726957328, lng: -82.99808226981662, popupText: 'Soroa' },
        { lat: 23.177495368631707, lng: -81.18129239475351, popupText: 'Varadero' },
        { lat: 23.068934723412145, lng: -82.2909757530438, popupText: 'Finca Vigia' },
        { lat: 23.174537614886702, lng: -82.23947932628494, popupText:  'Vychodne plaze'},
      ]

    const havanaMarkers = [
        { lat: 23.135297356460676, lng: -82.35946244412622, popupText: 'Kapitol' },
        { lat: 23.1373821914029, lng: -82.35850601203275, popupText: 'Central Park' },
        { lat: 23.12463440943136, lng: -82.38553379863257, popupText: 'Plaza de la Revolución' },
        { lat: 23.145783233517815, lng: -82.35875032377733, popupText: 'Malecon' },
        { lat: 23.137371886710937, lng: -82.38624858656065, popupText: 'La Rampa' },
        { lat: 23.05695949198991, lng: -82.4458786808882, popupText: 'Havana cementery' },
        { lat: 23.099923812096637, lng: -82.39884915883091, popupText: 'Havana forest' },
        { lat: 23.150024105086647, lng: -82.35652065328267, popupText: 'Morro' },
        { lat: 23.147120507830884, lng: -82.34960828995264, popupText: 'Cabaňa' },
        { lat: 22.99833876688517, lng: -82.40597549923085, popupText: 'José Martí international Airport' }
      ]


  return (
    <div id='destinations'>
        <div className='destination cubaDestinationText cubaMap'>
            <h2 className='destinationName'>{props.language.destinationHeading1}</h2>
            <h3>{props.language.destinationDistance1}</h3>
            <p>{props.language.destination1LiOne}</p>
            <p>{props.language.destination1LiTwo}</p>

            <h2 className='destinationName'>{props.language.destinationHeading2}</h2>
            <h3>{props.language.destinationDistance2}</h3>
            <p>{props.language.destination2LiOne}</p>

            <h2 className='destinationName'>{props.language.destinationHeading3}</h2>
            <h3>{props.language.destinationDistance3}</h3>
            <p>{props.language.destination3LiOne}</p>

            <h2 className='destinationName'>{props.language.destinationHeading4}</h2>
            <h3>{props.language.destinationDistance4}</h3>
            <p>{props.language.destination4LiOne}</p>
        </div>

        <div className='destinationMapContainer'>
            <h1 className='destinationName'>{props.language.cuba}</h1>
            <div className='destinationMap'>
                <Map markers={cubaMarkers} center={'cuba'}/>
            </div>
        </div>

        <div className='destinationMapContainer'>
            <h1 className='destinationName'>{props.language.havana}</h1>
            <div className='destinationMap'>
                <Map markers={havanaMarkers} center={'havana'}/>
            </div>
        </div>
        <div className='destination'>
            <h1 className='destinationName'>{props.language.inHavana}</h1>
            <ol className='havanaDestinationsList'>
                <li>{props.language.capitol}</li>
                <li>{props.language.centralPark}</li>
                <li>{props.language.piazzaDeLaRevolucion}</li>
                <li>{props.language.malecon}</li>
                <li>{props.language.laRampa}</li>
                <li>{props.language.havanaHotels}</li>
                <li>{props.language.havanaForest}</li>
                <li>{props.language.havanaCementery}</li>
                <li>{props.language.centrum}</li>
                <li>{props.language.castle}</li>
                <li>{props.language.cabana}</li>
                <li>{props.language.internationalAirport}</li>
            </ol>
        </div>
    </div>
  )
}

const Map = ({ markers, center }) => {
    let centerDestination, defaultZoom
    if (center === 'cuba') {
        centerDestination = [22.58700693966428, -81.62623643430526]
        defaultZoom = 8
        
    } else if (center === 'havana') {
        centerDestination = [23.100239547987325, -82.39070536786214]
        defaultZoom = 11.4
    }


    const customIcon = new Icon({
        iconUrl: pin,
        iconSize: [38, 38] //size
      })

    return (
      <MapContainer
        center={centerDestination}
        zoom={defaultZoom}
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, index) => (
          <Marker icon={customIcon} key={index} position={[marker.lat, marker.lng]}>
            <Popup>{marker.popupText}</Popup>
          </Marker>
        ))}
      </MapContainer>
    );
  };
  
