import React from 'react'
import './footer.css'
import { FaLocationDot, FaPhone, FaWhatsapp, FaYoutube, FaInstagram } from "react-icons/fa6";
import { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import pin from '../images/pin.png'
import { AiOutlineMail } from "react-icons/ai";
import havanaQR from '../images/havanaQR.png'


export default function Footer(props) {

  const markers = [
    { lat: 23.14394524232069, lng: -82.38150351828637, popupText:  'Hotel Nacional de Cuba'},
    { lat: 23.137387660256287, lng: -82.35887978374947, popupText:  'Parque Central'},
  ]

  return (
    <div id='footerContainer'>
        <h1 id='footerHeading'>{props.language.navbarItem4}</h1>
    <div className="gridContainer">
      <div className="gridItem">
      <div className='contactContainer'>
          <h2 className='contactHeading'>{props.language.contactUs}</h2>
          <ul className='contactList'>
            <li><FaLocationDot className='locationIconContactUs'/><a className='footerContactLink locationLink' href="#footerContainer">Havana Cuba</a></li>
            <li><FaPhone className='phoneIconContactUs' /><a className='footerContactLink phoneLink' href="tel:+5353059980">+ 53 530 59980</a></li>
            <li><FaWhatsapp className='whatsappIconContactUs'/><a className='footerContactLink whatsappLink' href="tel:+5353059980">+ 53 530 59980</a></li>
            <li><AiOutlineMail className='emailIconContactUs' /><a className='footerContactLink emailLink' href="mailto:ernestocpr94@gmail.com">ernestocpr94@gmail.com</a></li>
            <li><img className='QRcode' src={havanaQR} alt="" width='200' /></li>
          </ul>
        </div>
      </div>
      <div className="gridItem">
      <div className='mapContainer'>
          <h2 className="findHeading">{props.language.findUs}</h2>
          <div className='map'>
              <MapComponent markers={markers}/>
          </div>
      </div>
      </div>
    </div>
    <div className="socialMediaListContainer">
      <div className='socialMediaIcons'>
        <ul className='socialMediaList'>
          <li><a href="tel:+5353059980"><FaWhatsapp className='whatsappIcon'/></a></li>
          <li><a href="https://www.youtube.com" target='_blank'rel='noreferrer'><FaYoutube className='youtubeIcon' /></a></li>
          <li><a href="https://www.instagram.com" target='_blank' rel='noreferrer'><FaInstagram className='instagramIcon' /></a></li>
        </ul>
      </div>
      <div className="signature">© 2023 created by Mgr. Jozef Žitt - Freelancer - <a className='creatorEmail' href="mailto:dodo.zitt@gmail.com">dodo.zitt@gmail.com</a></div>
    </div>
    </div>
  )
}


const MapComponent = ({markers}) => {
  const position = [23.13550736803937, -82.36310031506034]; // Initial position for the map
  
  const customIcon = new Icon({
    iconUrl: pin,
    iconSize: [38, 38] //size
  })


  return (
    <MapContainer center={position} zoom={14} style={{ height: '300px', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
        {markers.map((marker, index) => (
          <Marker icon={customIcon} key={index} position={[marker.lat, marker.lng]}>
            <Popup>{marker.popupText}</Popup>
          </Marker>
        ))}
    </MapContainer>
  );
};