import { useState } from 'react'
import './App.css'
import { SK, EN, ES, FR, RU } from './language/language'
import Title from './components/title'
import Gallery from './components/gallery'
import Slideshow from './components/slideshow'
import Footer from './components/footer'
import Rating from './components/rating'
import Services from './components/services'
import Destinations from './components/destinations'

function App() {
  let [language, setLanguage] = useState(() => {
    let defaultLanguage = localStorage.getItem('language')
    if (defaultLanguage ===  null) return EN
    if (defaultLanguage === 'SK') return SK
    if (defaultLanguage === 'EN') return EN
    if (defaultLanguage === 'ES') return ES
    if (defaultLanguage === 'FR') return FR
    if (defaultLanguage === 'RU') return RU
  })

  function changeLanguage (lang) {
    localStorage.setItem('language', lang.language)
    setLanguage(lang)
  }

  const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 110) { setNavbar(true) } 
        else { setNavbar(false) }
    }
    
    window.addEventListener('scroll', changeBackground)

  return (
    <div className="App" >
      <Title language={language} navbar={navbar} changeLanguage={changeLanguage}/>
      <Slideshow language={language}  />
      <Services language={language} />
      <Destinations language={language} />
      <Gallery language={language} />
      <Rating language={language} />
      <Footer language={language}/>
    </div>
  )
}

export default App;